import React from "react";
import ContentBox from "../../components/molecules/ContentBox.Component";

const AboutViewDesktop = ({ lang, title, par, tags, animation, skills }) => {
  return (
    <div className="h-[1000px] bg-cover bg-center bg-no-repeat overflow-hidden">
      <div className="flex justify-center items-center relative z-10">
        <div className="p-10 w-2/3 mt-20">
          <div data-aos={animation} data-aos-delay={300} className="w-[900px]">
            <ContentBox
              tags={tags[lang]}
              title={{
                value: title.value[lang],
                style: "text-dark font-bold lg:text-6xl text-2xl",
              }}
              par={{ value: par.value[lang], style: "mt-5 text-2xl font-thin" }}
            />
          </div>
          <div className="overflow-hidden w-full mt-5">
            {skills.map((skill, index) => (
              <div
                key={index}
                data-aos={skill.animation}
                data-aos-delay={300 * index}
                className="float-left w-1/3 mt-5 pt-10 pr-10"
              >
                <ContentBox
                  key={index}
                  img={{
                    src: skill.img.src,
                    alt: skill.img.alt,
                    title: skill.img.title,
                    style: 'w-['+skill.img.size.w+'px] h-['+skill.img.size.h+'px]'
                  }}
                  title={{
                    value: skill.title.value[lang],
                    style: "text-3xl font-bold mt-5 mb-1",
                  }}
                  par={{ value: skill.par.value[lang] }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};


export default AboutViewDesktop;
