import React from "react";
import { IntrasectionConfig } from "../../config";
import IntrasectionDesktop from "./Intrasection_Desktop";
import IntrasectionMobile from "./Intrasection_Mobile";

const Intrasection = ({ lang, screen }) => {
  const { title, par, button, phone } = IntrasectionConfig(screen);

  return (
    <section className="bg-dark">
      {screen > 1380 ? (
        <IntrasectionDesktop
          lang={lang}
          title={title}
          par={par}
          button={button}
          phone={phone}
        />
      ) : (
        <IntrasectionMobile
          lang={lang}
          title={title}
          par={par}
          button={button}
          phone={phone}
        />
      )}
    </section>
  );
};

export default Intrasection;
