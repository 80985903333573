import { useEffect, useState } from 'react';
import * as EmailValidator from 'email-validator';

export const useScroll = () => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return scrollY;
};

export const validateEmail = (email) => {
    return EmailValidator.validate(email)
  };

export const drawPathOnScroll = (svg) => {
    const length = svg.getTotalLength();
    // start positioning of svg drawing
    svg.style.strokeDasharray = length;

    // hide svg before scrolling starts
    svg.style.strokeDashoffset = length;

    window.addEventListener("scroll", () => {
        const scrollpercent = (document.body.scrollTop + document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
    
        const draw = length * scrollpercent;
    
        // Reverse the drawing when scroll upwards
        svg.style.strokeDashoffset = length - draw;
    });
}
