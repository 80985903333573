import React from 'react'
import { GetInTouchConfig } from '../../config'
import GetInTouchDesktop from './GetInTouch_Desktop'
import GetInTouchMobile from './GetInTouch_Mobile'

const GetInTouch = ({ lang, screen, showTerms }) => {
    const { title, par, information, animation, form, errors } = GetInTouchConfig(screen)
    const handleShowTerms = () => {
        showTerms(true)
    }
    return (
       <div>
        {screen > 1380 
        ? <GetInTouchDesktop lang={lang} title={title} par={par} information={information} animation={animation} form={form} errors={errors} showTerms={handleShowTerms}/>
        : <GetInTouchMobile lang={lang} title={title} par={par} information={information} animation={animation} form={form} errors={errors}  showTerms={handleShowTerms}/>
        }
       </div>
    )
}

export default GetInTouch