import React, { useEffect, useRef } from 'react';
import LineSVG from './Line';
import PolylineSVG from './Polyline';

const AnimatedLine = ({w}) => {
    const line1 = useRef(null);
    const line2 = useRef(null);
    const line3 = useRef(null);
    const line4 = useRef(null);

    useEffect(() => {
        const setOpacity = (lines, opacity) => {
            lines.map(line => line.current.style.opacity = opacity)
        }
        const getScrollPercent = () => {
            return window.scrollY / (document.documentElement.scrollHeight - window.innerHeight);
        }
        const getLenght = (line) => {
            return line.current.getTotalLength();
        }
        const drawLine = (line, currentLength, totalLength) => {
            line.current.style.strokeDasharray = `${currentLength} ${totalLength}`;
        }

        const endLine = (currentLength, totalLength) => {
            return currentLength >= totalLength
        }

        const handleScroll = () => {
            if (line1.current) {
                setOpacity([line2, line3, line4], 0)
                const totalPathLength1 = getLenght(line1);
                const currentPathLength1 = totalPathLength1 * getScrollPercent();
                drawLine(line1, currentPathLength1 * 3, totalPathLength1)

                if (endLine(currentPathLength1 * 3, totalPathLength1)) {
                    setOpacity([line2], 1)
                    const totalPathLength2 = getLenght(line2);
                    const currentPathLength2 = (currentPathLength1 * 3 - totalPathLength1) * (totalPathLength2 / totalPathLength1);
                    drawLine(line2, currentPathLength2, totalPathLength2)

                    if (endLine(currentPathLength2, totalPathLength2)) {
                        setOpacity([line3], 1)
                        const totalPathLength3 = getLenght(line3);
                        const currentPathLength3 = (currentPathLength2 * 1.5 - totalPathLength2 * 1.5) * (totalPathLength3 / totalPathLength2);
                        drawLine(line3, currentPathLength3, totalPathLength3)

                        if (endLine(currentPathLength3, totalPathLength3)) {
                            setOpacity([line4], 1)
                            const totalPathLength4 = getLenght(line4);
                            const currentPathLength4 = (currentPathLength3 - totalPathLength3) * (totalPathLength4 / totalPathLength3 / 2);
                            drawLine(line4, currentPathLength4, totalPathLength4)

                        }
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section className='grid grid-cols-1 lg:grid-cols-6 w-full overflow-hidden'>
            <div></div>
            <div className='lg:col-span-4'>
                <div className={`h-[1000px] 3xl:w-[1600px] 2xl:w-[1300px] xl:w-[1000px]`}>
                    <LineSVG w={w} h={1000} stroke='#212121' strokeWidth='0.2rem' referring={line1} />
                </div>
                <div className={`h-[1300px] 3xl:w-[1600px] 2xl:w-[1300px] xl:w-[1000px]`}>
                    <PolylineSVG w={w} h={1300} points={`${w} 1 ${w} 1150 1 1150 1 1300`} stroke='#f6f6f6' strokeWidth='0.2rem' referring={line2} />
                </div>
                <div className={`h-[1300px] 3xl:w-[1600px] 2xl:w-[1300px] xl:w-[1300px]`}>
                    <LineSVG w={w} h={1300} x1={0} x2={0} stroke='#212121' strokeWidth='0.2rem' referring={line3} />
                </div>
                <div className={`h-[1300px] 3xl:w-[1600px] 2xl:w-[1300px] xl:w-[1000px]`}>
                    <PolylineSVG w={w} h={1300} points='1 0 1 1100 6400 1100' stroke='#f6f6f6' strokeWidth='0.2rem' referring={line4} />
                </div>
            </div>
            <div></div>
        </section>
    );
};

export default AnimatedLine;

