import React, { useEffect, useRef } from "react";
import TitleComponent from "../../components/atoms/Title.Component";

const OpenerViewDesktop = ({
  lang,
  title,
  subTitle,
  subSubTitle,
  background,
}) => {
  const pathRef = useRef(null);
  
  useEffect(() => {
    const path = pathRef.current;
    if (path) {

      const length = path ? path.getTotalLength() : 0;

      path.style.strokeDasharray = length;
      path.style.strokeDashoffset = length;

      const handleIntersect = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            path.parentElement.classList.add("intersecting");
            path.style.transition = "stroke-dashoffset 4s ease-in-out";
            path.style.strokeDashoffset = "0";
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersect, {
        threshold: 0.2,
      });

      observer.observe(path);

      return () => {
        observer.disconnect();
      };
    }
  }, []);
  return (
    <div
      id="opener"
      className="grid grid-cols-1 lg:grid-cols-6 w-full h-full "
    >
      <div></div>
      <div
        id="center"
        className={`lg:col-span-4 relative h-[2000px] 3xl:w-[1600px] 2xl:w-[1300px] xl:w-[1000px]`}
      >
        <div
          className={`absolute xl:top-[20%] lg:top-[10%] flex items-center overflow-hidden`}
        >
          <TitleComponent
            title={{
              value: title.value[lang],
              style: "text-8xl text-light font-thin p-10 mt-30",
            }}
            subTitle={{ value: subTitle.value[lang], style: "font-bold" }}
            subSubTitle={{
              value: subSubTitle.value[lang],
              style: "text-3xl uppercase font-semibold",
            }}
            level={1}
          />
        </div>
        <svg
          className={`fill-none`}
          stroke="#f6f6f6"
          strokeWidth="0.1rem"
          id="home_line_svg"
          data-name="home line"
          viewBox={`0 0 937.89 1200`}
        >
          <path
            ref={pathRef}
            id="home_line"
            d={`M1 0 L1 250 L936.89 250 L936.89 1200`}
          />
        </svg>
      </div>
      <div></div>
    </div>
  );
};

export default OpenerViewDesktop;
