import { Link } from "react-router-dom";
import { GetInTouchConfig, NavigationConfig } from "../../config";
import LogoImageSVG from "../atoms/LogoImageSVG";
import ImageSvg from "../atoms/ImageSVG";
import { HashLink } from "react-router-hash-link";

const Nav = ({ lang, isOpen, onHomeClick, onChangeLang, screen }) => {
  const { menu } = NavigationConfig(screen);
  const { information } = GetInTouchConfig(screen);

  const handleHomeClick = () => {
    onHomeClick();
  };

  const handleChangeLang = (selectedLang) => {
    onChangeLang(selectedLang);
  };

  const LangSwitch = ({ langToSet }) => {
    return (
      <p
        className={`m-10 rounded px-4 py-2 uppercase ${lang === langToSet ? "text-dark bg-[#f6f6f6]" : "text-light"
          }`}
        onClick={() => handleChangeLang(langToSet)}
      >
        {langToSet}
      </p>
    );
  };

  return (
    <nav className="w-full h-full relative">
      <div
        className={
          isOpen
            ? "w-full h-full bg-dark fixed top-0 left-[0px] font-epilogue flex animate-openmenu flex-col bg.dark p-12"
            : "w-full h-full bg-dark fixed top-0 animate-closemenu left-[-100%] flex flex-col p-12"
        }
      >

        <div className="flex justify-center items-center ">
          <LangSwitch langToSet={"it"} />
          <LangSwitch langToSet={"en"} />
        </div>
        {screen > 700 ? (
          <ul className="flex justify-center items-center w-full h-full">
            {menu.map((item, index) => (
              <li
                key={index}
                className="text-light lg:text-6xl text-4xl p-20 md:p-5 overflow-hidden font-bold"
                onClick={handleHomeClick}
                data-aos={item.animation}
                data-aos-delay={index * 200}
              >
                <HashLink to={item.href} className="p-2 group" aria-label={item.href}>
                  {item.value[lang]}
                  <div className="bg-[#f6f6f6] h-[5px] rounded w-0 group-hover:w-full transition-all duration-500"></div>
                  </HashLink>
              </li>
            ))}
          </ul>
        ) : (
          <ul className="block text-center w-full h-fit">
            {menu.map((item, index) => (
              <li
                key={index}
                className="text-light text-[190%] lg:text-4xl mb-3 overflow-hidden font-bold"
                onClick={handleHomeClick}
                data-aos={item.animation}
                data-aos-delay={index * 200}
              >
                <HashLink to={item.href} className="group" aria-label={item.href}>

                  {item.value[lang]}
                  <div className="bg-[#f6f6f6] rounded w-0 group-hover:w-full transition-all duration-500"></div>
                </HashLink>
              </li>
            ))}
          </ul>
        )}
        <div data-aos="fade-up"
          data-aos-delay={200} className='flex justify-center items-center mt-2'>
          <Link to='https://it.linkedin.com/company/open-cluster-s-r-l' target="_blank" rel="noopener noreferrer" aria-label="linkedin"><ImageSvg type='linkedin' width={30} fill='#f6f6f6' /></Link>
        </div>
        <div
          className=" mt-8 flex justify-center items-center text-light"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <p>
            &copy; {new Date().getFullYear}
            {information.address.value[lang]}, {information.vat.value[lang]}
          </p>

        </div>
        <div
          className=" flex justify-center items-center text-light"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <LogoImageSVG
            type="portrait"
            stroke="#f6f6f6"
            fill="#f6f6f6"
            width={200}
          />
        </div>
      </div>
    </nav>
  );
};

export default Nav;
