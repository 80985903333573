import React from 'react'
import ContentBox from '../../components/molecules/ContentBox.Component'

const AboutViewMobile = ({lang, title, par, tags, animation, skills}) => {
    return (
        <div className='w-full h-full p-10'>
            <div data-aos={animation} data-aos-delay={300} className='w-fit'>
                <ContentBox

                    tags={tags[lang]}
                    title={{ value: title.value[lang], style: 'text-dark font-bold text-5xl' }}
                    par={{ value: par.value[lang], style: 'mt-5 text-md font-regular' }}
                />
            </div>
            <div
                className='overflow-hidden w-full h-full mt-5'>
                {skills.map((skill, index) => (
                    <div key={index} data-aos={skill.animation} data-aos-delay={index} className=' shadow-lg border p-10 bg-gray-50 rounded-xl w-full mt-5 pt-10 pr-10'>
                        <ContentBox
                            key={index}
                            img={{ src: skill.img.src, alt: skill.img.alt, title: skill.img.title, style: 'w-[90px] h-[80px] mb-5' }}
                            title={{ value: skill.title.value[lang], style: "text-3xl md:text-2xl font-bold mt-5 mb-1 absolute top-5" }}
                            par={{ value: skill.par.value[lang], style:'text-sm font-regular ' }}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AboutViewMobile