import React from "react";
import { ProjectsConfig } from "../../config";
import ProjectsDesktop from "./Projects_Desktop";
import ProjectsMobile from "./Projects_Mobile";

const Projects = ({ lang, screen }) => {
  const { title, projects, tags, button } = ProjectsConfig(screen);

  return screen > 1380 ? (
    <ProjectsDesktop
      lang={lang}
      title={title}
      projects={projects}
      tags={tags}
      button={button}
    />
  ) : (
    <ProjectsMobile
    screen={screen}
    lang={lang}
    title={title}
    projects={projects}
    tags={tags}
    button={button}
  />
  );
};

export default Projects;
