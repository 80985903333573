import React, { useEffect, useState, Suspense } from 'react';
import OnePage from './OnePage';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { HashRouter, Routes, Route, BrowserRouter } from 'react-router-dom';

// Define a placeholder component for PrivacyPolicy
const PrivacyPolicyPlaceholder = () => (
  <div className='w-full h-screen flex items-center justify-center'>
    <span className='loader'></span>
    </div>
);

// Use React.lazy to import PrivacyPolicy component asynchronously
const PrivacyPolicyLazy = React.lazy(() => import('./views/privacy-policy/Privacy-Policy'));

const App = () => {
  const [privacyPolicyLoaded, setPrivacyPolicyLoaded] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
  }, []);

  useEffect(() => {
    // Simulate fetching of privacy policy data
    setTimeout(() => {
      setPrivacyPolicyLoaded(true);
    }, 2000); // Adjust the timeout as needed
  }, []);

  return (
    <div className='font-Gilroy'>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/*" element={<OnePage />} />
          {/* Render PrivacyPolicy component conditionally */}
          <Route
            path="/privacy"
            element={
              privacyPolicyLoaded ? (
                <Suspense fallback={<PrivacyPolicyPlaceholder />}>
                  <PrivacyPolicyLazy />
                </Suspense>
              ) : (
                <PrivacyPolicyPlaceholder />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
