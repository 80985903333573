import React from 'react';

const TitleComponent = ({ title, subTitle, subSubTitle, level }) => {
    const HeadingTag = `h${level || 1}`;

    return (
        <HeadingTag 
            className={title.style} 
            data-aos={title.animation ? title.animation.type : undefined}
            data-aos-delay={title.animation ? title.animation.delay : undefined}
        >
            {title.value}
            {subTitle && (
                <>
                    <br />
                    <span className={subTitle.style}>{subTitle.value}</span>
                </>
            )}
            {subSubTitle && (
                <>
                    <br />
                    <span className={subSubTitle.style}>{subSubTitle.value}</span>
                </>
            )}
        </HeadingTag>
    );
};

export default TitleComponent;
