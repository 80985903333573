import React, { useEffect, useState } from "react";
import LogoImageSVG from "../atoms/LogoImageSVG";
import MenuSVG from "../atoms/MenuSVG";
import Nav from "./Menu.Component";

const NavigationBar = ({ lang, color, changeLang, screen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolledFromTop, setScrolledFromTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setScrolledFromTop(false);
      } else {
        setScrolledFromTop(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleHomeClick = () => {
    setIsMenuOpen(false);
  };

  const handleChangeLang = (selectedLang) => {
    changeLang(selectedLang);
  };

  return (
    <div className="w-full">
      {screen > 1380 ? (
        <div className={`w-full fixed top-0 z-10 ${scrolledFromTop ? 'bg-dark bg-opacity-90 h-[70px]' : null}`}>
          <div className={`absolute ${scrolledFromTop ? 'top-2' : 'top-10'} left-20`} onClick={handleToggleMenu}>
            <MenuSVG
              type={isMenuOpen ? "close" : "hamburger"}
              stroke={"#f6f6f6"}
              fill={"#f6f6f6"}
              width={60}
            />
          </div>
          <div className={`absolute ${scrolledFromTop ? 'top-2' : 'top-10'} right-20`}>
            <LogoImageSVG
              type="mark"
              stroke={"#f6f6f6"}
              fill={"#f6f6f6"}
              width={90}
            />
          </div>
        </div>
      ) : (
        <div className="w-full fixed h-[50px] bg-dark top-0 z-10">
          <div className="absolute top-2 left-5" onClick={handleToggleMenu}>
            <MenuSVG
              type={isMenuOpen ? "close" : "hamburger"}
              stroke="#f6f6f6"
              fill="#f6f6f6"
              width={35}
            />
          </div>
          <div className="absolute right-5 top-2 ">
            <LogoImageSVG
              type="mark"
              stroke="#f6f6f6"
              fill="#f6f6f6"
              width={45}
            />
          </div>
        </div>
      )}
      <div className="z-0">
        {isMenuOpen ? (
          <Nav
            screen={screen}
            lang={lang}
            isOpen={isMenuOpen}
            onHomeClick={handleHomeClick}
            onChangeLang={handleChangeLang}
          />
        ) : null}
      </div>
    </div>
  );
};

export default NavigationBar;
