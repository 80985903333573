import React from 'react';

const LineSVG = ({w, h, x1, x2, y2, stroke, strokeWidth, referring}) => {
    const lineX1 = x1 !== undefined ? x1 : w;
    const lineX2 = x2 !== undefined ? x2 : w;
    const lineY2 = y2 !== undefined ? y2 : h;

    return (
        <svg
            className={`fill-none h-full w-full relative`}
            stroke={stroke}
            strokeWidth={strokeWidth}
            viewBox={`0 0 ${w+0.89} ${h}`}
        >
            <g>
                <line x1={lineX1} x2={lineX2} y2={lineY2} ref={referring} />
            </g>
        </svg>
    )
}

export default LineSVG;