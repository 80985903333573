const support_format_webp = () => {
  var elem = document.createElement("canvas");
  if (!!(elem.getContext && elem.getContext("2d"))) {
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  } else {
    return false;
  }
};

const WEBP_PNG = (screen, image, size) => {
  if (screen > size) {
    return support_format_webp
      ? require(`./assets/${image}.webp`)
      : require(`./assets/${image}.png`)
  } else {
    return support_format_webp
      ? require(`./assets/mobile/${image}.webp`)
      : require(`./assets/mobile/${image}.png`)
  }
}

export const NavigationConfig = (screen) => {
  return {
    menu: [
      {
        value: {
          it: "Home",
          en: "Home",
        },
        href: "#opener",
        animation: "fade-up-right",
      },
      {
        value: {
          it: "About",
          en: "About",
        },
        href: "#about",
        animation: "fade-down",
      },
      {
        value: {
          it: "Progetti",
          en: "Projects",
        },
        href: "#project",
        animation: "fade-up",
      },
      {
        value: {
          it: "Contatti",
          en: "Contacts",
        },
        href: "#contact",
        animation: "fade-down-left",
      },
    ],
  }
};

export const OpenerConfig = (screen) => {
  return {
    background: WEBP_PNG(screen, 'opener', 1200),
    title: {
      value: {
        it: "Yourself Place",
        en: "Yourself Place",
      },
    },
    subTitle: {
      value: {
        it: "OpenCluster",
        en: "OpenCluster",
      },
    },
    subSubTitle: {
      value: {
        it: "SOFTWARE, RESEARCH & ADVICE",
        en: "SOFTWARE, RESEARCH & ADVICE",
      },
    }
  }
};

export const AboutConfig = (screen) => {
  return {
    tags: {
      it: [`Chi Siamo`],
      en: [`Who We Are`],
    },
    title: {
      value: {
        it: `Start Up`,
        en: `Start Up`,
      },
    },
    par: {
      value: {
        it: `Open Cluster è all'avanguardia nell'eccellenza operativa. La nostra startup sfrutta l'intelligenza artificiale e l'apprendimento automatico per rivoluzionare i processi aziendali, promuovendo efficienza ed economicità. Specializzati nell'identificare e superare i colli di bottiglia, offriamo soluzioni su misura che trasformano i flussi di lavoro tradizionali in modelli di efficienza; le nostre innovazioni hanno dimostrato di migliorare significativamente le prestazioni e ridurre i costi operativi. Unisciti a noi nel ridefinire ciò che le aziende possono raggiungere con l'automazione intelligente. Open Cluster: Innovare per l'Efficienza.`,
        en: `Open Cluster is at the forefront of operational excellence. Our startup leverages artificial intelligence and machine learning to revolutionize business processes, promoting efficiency and cost-effectiveness. Specializing in identifying and overcoming bottlenecks, we offer tailored solutions that transform traditional workflows into models of efficiency; our innovations have been proven to significantly enhance performance and reduce operational costs. Join us in redefining what businesses can achieve with smart automation. Open Cluster: Innovating for Efficiency.`,
      },
    },
    animation: "fade-up",
    skills: [
      {
        img: {
          src: WEBP_PNG(screen, 'circles', 1200),
          alt: "two overlapped circles icons one pink one cyan",
          title: "Circles",
          size: {
            w: 100,
            h: 69
          }
        },
        title: {
          value: {
            it: "R&S",
            en: "R&D",
          },
        },
        par: {
          value: {
            it: `Promozione di nuove tecnologie e soluzioni che spingono le industrie in avanti, migliorando la competitività e preparando il terreno per futuri progressi e crescita economica.`,
            en: `Promotion of new technologies and solutions that push industries forward, improving competitiveness and laying the groundwork for future advancements and economic growth.`,
          },
        },
        animation: "zoom-left",
      },
      {
        img: {
          src: WEBP_PNG(screen, 'squares'),
          alt: "two overlapped squares icons one pink one cyan",
          title: "Squares",
          size: {
            w: 100,
            h: 69
          }
        },
        title: {
          value: {
            it: `Software`,
            en: `Software`,
          },
        },
        par: {
          value: {
            it: `Utilizzo di soluzioni software per l'efficienza operativa, l'affidabilità e la sicurezza, migliorando l'esperienza utente, ottimizzando i flussi di lavoro, proteggendo i dati, guidando infine al successo aziendale e alla soddisfazione del cliente.`,
            en: `Using software solutions for operational efficiency, reliability, and security, enhancing the user experience, optimizing workflows, protecting data, and ultimately leading to business success and customer satisfaction.`,
          },
        },
        animation: "zoom-bottom",
      },
      {
        img: {
          src: WEBP_PNG(screen, 'circle_square'),
          alt: "one pink square and one cyan circle",
          title: "Circles Squares",
          size: {
            w: 100,
            h: 69
          }
        },
        title: {
          value: {
            it: `Consulenza`,
            en: `Consulting`,
          },
        },
        par: {
          value: {
            it: `Consulenza strategica e soluzioni personalizzate per guidare le aziende verso il successo, ottimizzare i processi e migliorare la competitività sul mercato.`,
            en: `Strategic consulting and customized solutions to guide businesses towards success, optimize processes, and improve market competitiveness.`,
          },
        },
        animation: "zoom-right",
      },
    ]
  }
};

export const IntrasectionConfig = (screen) => {
  return {
    title: {
      value: {
        it: [
          { value: "Creatività.", animation: "fade-left" },
          { value: "Innovazione.", animation: "fade-right" },
          { value: "Tecnologia.", animation: "fade-left" },
        ],
        en: [
          { value: "Creativity.", animation: "fade-left" },
          { value: "Innovation.", animation: "fade-right" },
          { value: "Technology.", animation: "fade-left" },
        ],
      },
    },
    par: {
      value: {
        it: "La creatività incontra soluzioni all'avanguardia. Il nostro gruppo si impegna nello sviluppo di soluzioni innovative. Abbraccia il futuro dove l'immaginazione si trasforma in realtà.",
        en: "Creativity meets cutting-edge solutions. Our team is dedicated to crafting avant-garde services. Embrace the future where imagination transforms into reality.",
      },
      animation: "fade-bottom",
    },
    button: {
      value: {
        it: "Vedi Progetti",
        en: "View Projects",
      },
      animation: "fade-button",
    },

    phone: {
      images: [
        {
          src: WEBP_PNG(screen, 'phone-first', 1200),
          alt: "an IPhone with a mockup version of a site on the screen",
          title: "IPhone",
        },
        {
          src: WEBP_PNG(screen, 'phone-second', 1200),
          alt: "an IPhone with a mockup version of a site on the screen",
          title: "IPhone",
        },
        {
          src: WEBP_PNG(screen, 'phone-third', 1200),
          alt: "an IPhone with a mockup version of a site on the screen",
          title: "IPhone",
        },
        {
          src: WEBP_PNG(screen, 'phone-fouth', 1200),
          alt: "an IPhone with a mockup version of a site on the screen",
          title: "IPhone",
        },
        {
          src: WEBP_PNG(screen, 'phone-fifth', 1200),
          alt: "an IPhone with a mockup version of a site on the screen",
          title: "IPhone",
        },
      ],
    }
  }
};

export const ProjectsConfig = (screen) => {
  return {
    title: {
      value: {
        it: "Progetti",
        en: "Projects",
      },
    },
    tags: {
      it: ["I nostri Progetti"],
      en: ["Our Projects"],
    },
    button: {
      value: {
        it: "Descrizione",
        en: "Description",
      },
    },
    projects: [
      {
        logo: 'identitaDigitale',
        media: {
          src: WEBP_PNG(screen, 'identitaDigitale', 800),
          type: "image",
          alt: "Gif animata del sito web identià digitale",
          title: "identità Digitale",
        },
        title: {
          value: {
            it: "Identità Digitale",
            en: "Identità Digitale",
          },
        },
        tags: {
          it: [`sito web`],
          en: [`website`],
        },
        par: {
          value: {
            it: "Identità Digitale fornisce la Self Sovereign Identity, un modello di identità digitale “distribuita” basata su tecnologia DLT (Blockchain), che dà all’utente il pieno controllo della sua identità e delle informazioni da condividere. Con ID sei finalmente proprietario di te stesso senza che terze parti debbano garantire per te. Memorizza la tua Identità nel tuo wallet ed utilizzala per entrare in tutti i nostri siti e quelli dei partner, potrai essere te stesso e gestire la tua vita ed i tuoi beni dentro una community decentralizzata. Nessuna credenziale, solo un ID unico: attivala adesso!",
            en: `Identità Digitale provides Self Sovereign Identity, a "distributed" digital identity model based on DLT (Blockchain) technology, which gives the user full control of their identity and the information to share. With ID, you are finally the owner of yourself without needing third parties to vouch for you. Store your Identity in your wallet and use it to access all our sites and those of our partners, allowing you to be yourself and manage your life and assets within a decentralized community. No credentials, just a unique ID: activate it now!`,
          },
        },
        button: {
          value: {
            it: "Visita",
            en: "Visit",
          },
          href: "https://identitadigitale.io/",
        },
      },
      {
        logo: 'notarizeMe',
        media: {
          src: WEBP_PNG(screen, 'notarizeMe', 800),
          type: 'image',
          alt: "Gif animata del sito web Notarize[M]e",
          title: "Notarize[M]e",
        },
        title: {
          value: {
            it: "Notarize[M]e",
            en: "Notarize[M]e",
          },
        },
        tags: {
          it: [`sito web`],
          en: [`website`],
        },
        par: {
          value: {
            it: `Notarizzare un documento sulla blockchain assicura la sua inalterabilità: chi lo riceve può attestare che non è stato modificato da quando è stato registrato. Grazie a una funzione di hashing, che genera un'impronta digitale unica, è possibile verificare in qualsiasi momento l'autenticità del documento e rilevare eventuali alterazioni al suo contenuto. Notariz[M]e si rivolge sia agli utenti privati che alle aziende (tramite API) desiderose di proteggere i propri diritti, offrendo una soluzione semplice, accessibile e diretta. Scopri come Notariz[M]e può rivoluzionare la sicurezza documentale.`,
            en: `Notarizing a document on the blockchain ensures its immutability: the recipient can attest that it has not been altered since it was recorded. Thanks to a hashing function, which generates a unique digital fingerprint, it is possible to verify the authenticity of the document at any time and detect any alterations to its content. Notariz[M]e targets both private users and companies (via API) wishing to protect their rights, offering a simple, accessible, and direct solution. Discover how Notariz[M]e can revolutionize document security.`,
          },
        },
        button: {
          value: {
            it: "Visita",
            en: "Visit",
          },
          href: "https://notarizme.io/",
        },
      },
      {
        logo: 'motoreInteresse',
        media: {
          src: WEBP_PNG(screen, 'motoreInteresse', 800),
          type: "image",
          alt: "Immagine di un rete digitale",
          title: "Motore Di Intresse",
        },
        title: {
          value: {
            it: "Motore di Interesse",
            en: "Motore di Interesse",
          },
        },
        tags: {
          it: [],
          en: [],
        },
        par: {
          value: {
            it: `Il progetto Motore di Interesse, in seguito indicato semplicemente con MDI, si propone al mercato come sistema certificato di elaborazione, distribuzione e consultazione di informazioni immobiliari in real-time. MDI raccoglierà tutti i dati necessari seguendo un modello non strutturato o semi strutturato per la gestione di essi; l’adozione di un sistema di Data Lake rappresenta quindi una svolta per l’azienda in termini di:`,
            en: `The Motore Di Interesse project, simply referred as MDI, presents itself to the market as a certified system for processing, distributing, and consulting real estate information in real-time. MDI will collect all necessary data following an unstructured or semi-structured model for their management; the adoption of a Data Lake system thus represents a turning point for the company in terms of:`,
          },
          list: {
            value: {
              it: [
                "Notevole ampliamento delle informazioni alle quali si ha accesso",
                "Illimitati modi di interrogare i dati e possibilità di applicare a essi una grande varietà di tool differenti",
                "Riduzione dei costi di archiviazione e spazio infinito",
                "Riduzione dei costi di consolidamento dei dati",
                "Riduzione del Time-to-market",
                "Condivisione e democratizzazione dell’accesso alle informazioni",
              ],
              en: [
                "Significant expansion of the information accessible",
                "Unlimited ways to query the data and the possibility to apply a wide variety of different tools to it",
                "Reduction in storage costs and infinite space",
                "Reduction in data consolidation costs",
                "Reduction of Time-to-market",
                "Sharing and democratization of access to information",
              ],
            },
          },
        },
      },
      {
        logo: 'bender',
        media: {
          src: WEBP_PNG(screen, 'bender', 800),
          type: "image",
          alt: "Immaine di onde blu",
          title: "Bender",
        },
        title: {
          value: {
            it: "Bender",
            en: "Bender",
          },
        },
        tags: {
          it: [],
          en: [],
        },
        par: {
          value: {
            it: `Bender, il cui nome deriva dal verbo "piegare", trasforma il concetto in realtà "piegando" digitalmente le immagini. Il nostro obiettivo è semplificare la salvaguardia delle immagini da copie non autorizzate, rendendo le immagini originali virtualmente "inesistenti". Bender sarà accessibile via API per l'integrazione in qualsiasi sito web e disponibile come plug-in per i CMS più diffusi, offrendo una soluzione all'avanguardia per la protezione e la gestione delle immagini online.`,
            en: `Bender, from "to bend," turns the concept into reality by digitally "bending" images. Our goal is to simplify the safeguarding of images from unauthorized copies, making the original images virtually "non-existent." Bender will be accessible via API for integration into any website and available as a plugin for the most common CMSs, offering a cutting-edge solution for online image protection and management.`,
          },
        },
      },
    ]
  }
};

export const GetInTouchConfig = (screen) => {
  return {
    background: WEBP_PNG(screen, 'getintouch', 1200),
    title: {
      value: {
        it: "Hai una domanda?",
        en: "Got a question?",
      },
    },
    par: {
      value: {
        it: "Non hai trovato le informazioni che cercavi? Clicca il pulsante per metterti in contatto con noi.",
        en: "Didn't find the information you were looking for? Click the button to get in touch with us.",
      },
    },
    information: {
      address: {
        value: {
          it: "Via delle Grazie, 5 - 57125 Livorno",
          en: "Via delle Grazie, 5 - 57125 Leghorn",
        },
      },
      vat: {
        value: {
          it: "C.F. / Partita IVA 01994150496",
          en: "C.F. / VAT Number 01994150496",
        },
      },
    },
    animation: "fade-right",
    button: {
      value: {
        it: "Contattaci",
        en: "Get In Touch",
      },
      animation: "fade-up",
    },
    form: {
      message: {
        value: {
          it: "Inviaci un messaggio",
          en: "Send a Message",
        },
      },
      fields: [
        {
          label: {
            value: {
              it: "Come ti Chiami?",
              en: "Who you are?",
            },
          },
        },
        {
          label: {
            value: {
              it: "Qual'è la tua e-mail?",
              en: "Whats's your e-mail?",
            },
          },
        },
        {
          label: {
            value: {
              it: "Oggetto",
              en: "Object",
            },
          },
        },
        {
          label: {
            value: {
              it: "Messaggio",
              en: "Message",
            },
          },
        },
        {
          label: {
            value: {
              it: "Accetto i termini e le codizioni",
              en: "Accept Terms and Coditions",
            },
          },
        },
      ],
      button: {
        value: {
          it: "Invia",
          en: "Submit",
        },
      },
    },
    errors: {
      name: {
        value: {
          it: 'Inserisci il nome',
          en: 'Please enter a valid name'
        }
      },
      email: {
        value: {
          it: 'Inserisci un indirizzo email valido',
          en: 'Please enter a valid email address'
        }
      },
      object: {
        value: {
          it: 'Inserisci un oggetto valido',
          en: 'Please enter a valid object'
        }
      },
      message: {
        value: {
          it: 'Inserisci un messaggio valido',
          en: 'Please enter a valid message'
        }
      },
      terms: {
        value: {
          it: 'Accetta i termini',
          en: 'Please accept the terms'
        }
      },
      send: {
        value: {
          it: 'Errore, prova più tardi',
          en: 'Error, try later'
        }
      }
    }
  }
};
