import React from "react";
import ContentBox from "../../components/molecules/ContentBox.Component";
import ButtonComponent from "../../components/atoms/Button.Component";
import { HashLink } from "react-router-hash-link";

const IntrasectionMobile = ({ lang, title, par, button, phone }) => {
  return (
    <div className="w-full h-full p-10 overflow-hidden">
      <div className="relative">
        <div className="w-full h-full opacity-35">
          {phone.images.map((phone, index) => (
            <img
                data-aos='fade-right'
                data-aos-delay={200*index}
              src={phone.src}
              alt={phone.alt}
              title={phone.title}
              key={index}
              className="w-98 absolute"
            />
          ))}
        </div>
      </div>
      <div className="w-full h-full">
        <ContentBox
          title={title.value[lang].map((title, index) => ({
            value: title.value,
            style: `text-5xl md:text-6xl font-bold mt-5 mb-1 text-light`,
            animation: { type: title.animation, delay: 200 * index },
          }))}
          par={{
            value: par.value[lang],
            style: "mt-10 text-md md:text-2xl text-light font-thin",
            animation: { type: par.animation, delay: 200 },
          }}
        />
            <HashLink to="/#project" aria-label="projects">
          <ButtonComponent
            label={button.value[lang]}
            animation={{ type: button.animation, delay: 200 }}
            style="ml-2 transition-colors duration-300 ease-in-out bg-transparent w-full md:w-1/2 h-fit border-2 rounded hover:bg-light hover:text-dark-21 border-[#f6f6f6] mt-12 text-md md:text-xl text-light font-bold px-10 md:px-20 py-5"
          />
          </HashLink>
      </div>
    </div>
  );
};

export default IntrasectionMobile;
