import React from 'react'
import { Link } from 'react-router-dom'

const Input = ({ label, type, realName, altName, realValue, altValue, realChange, altChange, labelLink }) => {

    const handleChange = (e) => {
        realChange(e.target)
    }
    const handleAltChange = (e) => {
        altChange(e.target)
    }
    return (
        <label className='text-light text-xl w-full relative'>
            {type === 'checkbox' ? labelLink ? <Link to={labelLink} target="_blank" rel="noopener noreferrer" className='underline text-linght'>{label}</Link> : label : null}
            <input type={type} name={altName} value={altValue} onChange={handleAltChange} className='text-black inputStyle' />
            {type === 'checkbox'
                ? <><input
                    name={realName}
                    onChange={handleChange}
                    type={type}
                    className="before:content[''] peer relative h-5 w-5 left-5 top-1 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                />
                    <div className="pointer-events-none absolute top-2/4 -right-6 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3.5 w-3.5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="1"
                        >
                            <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </div></>
                : type === 'textarea' ? <textarea name={realName} value={realValue} row={4} col={50} placeholder={label+'*'} onChange={handleChange} className='bg-transparent border-b-2 mb-8 w-full h-32'/> : <input type={type} placeholder={label+'*'} name={realName} value={realValue} onChange={handleChange} className='bg-transparent border-b-2 mb-8 w-full' />
            }
            <br />
        </label>
    )
}

export default Input