import React, { useState } from 'react';
import Input from '../atoms/Input.Componet';
import { validateEmail } from '../../utils';

const MailForm = ({ lang, form, errors }) => {
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isSend, setSend] = useState(false)
  const subj = lang === 'it' ? '[Richiesta Informazioni] - ' : '[Request Information] - '
  const [formData, setFormData] = useState({
    obnf: '', // name
    fbnjm: '', //email
    pchfdu: '', // object
    nfttbjf: '', //message
    ltDifdlfe: false, //isChecked
  });

  const [altFormData, setAltFormData] = useState({
    name: '',
    email: '',
    object: '',
    message: '',
    isChecked: false,
  });

  const handleChange = (target) => {
    const { name, value, type, checked } = target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleAltChange = (target) => {
    const { name, value, type, checked } = target;
    setAltFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!formData.obnf || altFormData.name) {
      setError(true)
      setErrorMessage(errors.name.value)
      return;
    }

    if (!validateEmail(formData.fbnjm) || altFormData.email) {
      setError(true);
      setErrorMessage(errors.email.value);
      return;
    }

    if(!formData.pchfdu || altFormData.object) {
      setError(true);
      setErrorMessage(errors.object.value);
      return;
    }

    if(!formData.nfttbjf || altFormData.message) {
      setError(true);
      setErrorMessage(errors.message.value);
      return;
    }

    if(!formData.ltDifdlfe || altFormData.isChecked) {
      setError(true);
      setErrorMessage(errors.terms.value);
      return;
    }

    setError(false)
    try { 
      const response = await fetch('https://api.opencluster.it/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Name: formData.obnf,
          ToEmail: formData.fbnjm,
          Subject: subj + formData.pchfdu,
          Message: formData.nfttbjf,
        }),
      });

      if (response.ok) {
        setSend(true)
        setFormData({
          obnf: '',
          fbnjm: '',
          pchfdu: '',
          nfttbjf: '',
          ltDifdlfe: false,
        });
        setAltFormData({
          name: '',
          email: '',
          object: '',
          message: '',
          isChecked: false,
        });
      } else {
        throw new Error('Failed to send email');
      }
    } catch (error) {
      setError(true)
      setErrorMessage(errors.send.value)
    }
  };

  return (
    <>
      {isSend ? <p className='p-10 bg-green-500 bg-opacity-70 rounded xl:w-fit xl:text-left text-center w-full text-light font-regular'>{lang==='it' ? 'Email inviata con succcesso!' : 'Email send succesfully!'}</p> :
      <form onSubmit={handleSubmit}>
        <Input label={form.fields[0].label.value[lang]} error={error} type="text" altName='name' realName='obnf' realValue={formData.obnf} altValue={altFormData.name} realChange={handleChange} altChange={handleAltChange} />
        <Input label={form.fields[1].label.value[lang]} error={error} type="email" altName='email' realName='fbnjm' realValue={formData.fbnjm} altValue={altFormData.email} realChange={handleChange} altChange={handleAltChange} />
        <Input label={form.fields[2].label.value[lang]} error={error} type="text" altName='object' realName='pchfdu' realValue={formData.pchfdu} altValue={altFormData.object} realChange={handleChange} altChange={handleAltChange} />
        <Input label={form.fields[3].label.value[lang]} error={error} type="textarea" altName='message' realName='nfttbjf' realValue={formData.nfttbjf} altValue={altFormData.message} realChange={handleChange} altChange={handleAltChange} />
        <Input label={form.fields[4].label.value[lang]} error={error} type="checkbox" altName='isChecked' realName='ltDifdlfe' realValue={formData.ltDifdlfe} altValue={altFormData.isChecked} realChange={handleChange} altChange={handleAltChange} labelLink="/privacy"/>
        {error ? <p className='text-light w-fit p-2 rounded mt-5 bg-red-500'>{errorMessage[lang]}</p> : null}

        <button type="submit" className='transition-colors uppercase duration-300 ease-in-out bg-transparent w-fit h-fit border-2 rounded hover:bg-light hover:text-dark-21 border-[#f6f6f6] mt-12 text-xl text-light font-bold px-20 py-5'>{form.button.value[lang]}</button>

      </form>}
    </>

  );
};

export default MailForm;
