import React from "react";
import ButtonComponent from "../../components/atoms/Button.Component";
import ContentBox from "../../components/molecules/ContentBox.Component";
import ImgComponent from "../../components/atoms/Img.Component";
import { HashLink } from "react-router-hash-link";

const IntrasectionDesktop = ({ lang, title, par, button, phone }) => {
  return (
    <div className="w-full h-[1300px] pb-53 overflow-hidden">
      <div className="flex justify-center items-center relative z-10 ">
        <>
          <div className="w-2/5 float-left absolute left-40 top-20 xl:top-20">
            {phone.images.map((phone, index) => (
              <ImgComponent
                src={phone.src}
                alt={phone.alt}
                title={phone.title}
                key={index}
                style="w-full absolute"
                animation={{ type: "fade-right", delay: 500 * index }}
              />
            ))}
          </div>
          <div className="float-right absolute right-40 md:right-60 w-1/3 top-64">
            <ContentBox
              title={title.value[lang].map((title, index) => ({
                value: title.value,
                style: `text-7xl font-bold mt-5 mb-1 text-light`,
                animation: { type: title.animation, delay: 200 * index },
              }))}
              par={{
                value: par.value[lang],
                style: "mt-10 text-xl text-light w-[500px] font-thin",
                animation: { type: par.animation, delay: 200 },
              }}
            />
            <HashLink to="/#project" aria-label="projects">
              <ButtonComponent
                label={button.value[lang]}
                animation={{ type: button.animation, delay: 200 }}
                style="transition-colors duration-300 ease-in-out bg-transparent w-fit h-fit border-2 rounded hover:bg-light hover:text-dark-21 border-[#f6f6f6] mt-12 text-xl text-light font-bold px-20 py-5"
              />
              </HashLink>
          </div>
        </>
      </div>
    </div>
  );
};

export default IntrasectionDesktop;
