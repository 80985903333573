import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import ContentBox from "../../components/molecules/ContentBox.Component";
import ImageSvg from "../../components/atoms/ImageSVG";
import { HashLink } from "react-router-hash-link";

const ProjectsMobile = ({ screen, lang, title, projects, tags }) => {
  const options = {
    role: "tab",
    perPage: 1,
  };

  return (
    <div className="w-full h-full">
      <div className="px-10 pt-10">
        <ContentBox
          tags={tags[lang]}
          title={{
            value: title.value[lang],
            style: "text-dark font-bold text-5xl",
          }}
          par={{ value: "", style: "" }}
        />
      </div>
      <div
        data-aos="fade-right"
        data-aos-delay={200}
        className="w-[100%] flex items-center justify-center shadow-xl"
      >
        <Splide aria-label="Projects" options={options} className="w-full h-full">
          {projects.map((project, index) => (
            <SplideSlide key={index} className="border-2 border-transparent shadow-xl">
              <HashLink
                to={project.button ? project.button.href : "#projects"}
                target={project.button ? "_blank" : null}
                rel="noreferrer"
              >
                <div className="w-full h-none bg-[#eee] overflow-hidden">
                  <div
                    className="w-full flex items-center justify-center h-40 md:h-80 bg-no-repeat bg-cover bg-center overflow-hidden rounded-t-xl shadow-xl"
                    style={{
                      backgroundImage: `url(${project.media.src})`,
                    }}
                  ><ImageSvg type={project.logo} fill={'#f6f6f6'} stroke={'#f6f6f6'} width={screen < 1380 ? '20%' : '40%'} /></div>
                  <div className="text-center rounded-b-xl pb-5">
                    <h1 className="text-dark md:text-xl font-bold pt-5">
                      {project.title.value[lang]}
                    </h1>
                  </div>
                </div>
              </HashLink>
            </SplideSlide>
          ))}
          <div className="splide__arrows hidden" />
        </Splide>
      </div>
    </div>
  );
};

export default ProjectsMobile;
