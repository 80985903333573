import React, { useState } from "react";
import ContentBox from "../../components/molecules/ContentBox.Component";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/sea-green";
import ImageSvg from "../../components/atoms/ImageSVG";
import { HashLink } from "react-router-hash-link";
const ProjectsDesktop = ({ lang, title, projects, tags, button }) => {
  const [isDescrShow, setDescrShow] = useState(false);

  const options = {
    rewind: true,
    role: "tab",
    perPage: 1,
  };

  return (
    <div className="h-[1300px] bg-cover bg-center bg-no-repeat overflow-hidden">
      <div className="flex justify-center items-center relative z-10 left-0">
        <div className="p-10 w-2/3 mt-20">
          <div data-aos='fade-right'
            data-aos-delay={200}>
            <ContentBox
              tags={tags[lang]}
              title={{
                value: title.value[lang],
                style: "text-dark font-bold lg:text-6xl text-2xl",
              }}
              par={{ value: "", style: "" }}
            />
          </div>
          <div data-aos='fade-right'
            data-aos-delay={200} className="h-full absolute w-full top-0 2xl:left-72 left-0 mt-60">
            <Splide aria-label="Projects" options={options}>
              {projects.map((project, index) => (
                <SplideSlide key={index}>
                  <div className="2xl:w-[70%] w-full relative">
                    <div className="z-0 w-full h-full overflow-hidden bg-light rounded-2xl">
                    <HashLink to={
                          project.button ? project.button.href : "#projects"
                        } className="p-2 group" aria-label='project'  target={project.button ? "_blank" : null}
                        rel="noreferrer" >

                        {project.media.type === "image" ? (
                          <div
                            className="w-full flex justify-center items-center h-[600px] bg-dark bg-no-repeat bg-cover bg-center overflow-hidden"
                            style={{
                              backgroundImage: `url(${project.media.src})`,
                            }}
                          ><ImageSvg type={project.logo} fill={'#f6f6f6'} stroke={'#f6f6f6'} width={'30%'} /></div>
                        ) : (
                          <video
                            autoPlay
                            muted
                            loop
                            id="myVideo"
                            className="w-full h-full border p-5 rounded-xl bg-white hover:opacity-90"
                          >
                            <source src={project.media.src} type="video/mp4" />
                          </video>
                        )}
                      </HashLink>
                      <p
                        className="absolute cursor-pointer top-10 right-10 bg-light p-3 rounded shadow-xl transition-all  duration-300 ease-in-out hover:bg-blue-500 hover:text-light hover:fill-[#f6f6f6]"
                        onClick={() => setDescrShow(true)}
                      >
                        <span className="float-left mr-2 relative top-0.5" ><ImageSvg type='show' width={20} /></span>
                        <span>{button.value[lang]}</span>
                      </p>
                    </div>
                    {isDescrShow ? (
                      <div className="w-full h-full p-20 absolute top-0 m-auto rounded-xl right-0 bg-[#eee] overflow-hidden">

                        <div className="absolute h-full opacity-30 right-60 top-10">
                          <div data-aos="fade-left"
                            data-aos-delay={300}>
                            <ImageSvg type={project.logo} fill={'#212121'} stroke={'#212121'} width={'200%'} />
                          </div>
                        </div>
                        <p
                          className="absolute cursor-pointer top-10 right-10 bg-dark p-3 rounded shadow-xl transition-all  duration-300 ease-in-out hover:bg-gray-500 text-light fill-[#f6f6f6] "
                          onClick={() => setDescrShow(false)}
                        >
                          <span className="float-left mr-2 relative top-0.5" ><ImageSvg type='hide' width={20} /></span>
                          <span>{button.value[lang]}</span>
                        </p>
                        <h1
                          data-aos="fade-down"
                          data-aos-delay={100}
                          className="text-3xl text-dark font-bold mb-10 w-fit float-left"
                        >
                          {project.title.value[lang]}
                          {project.button ? (
                           
                              <HashLink to={project.button.href} target="_blank"
                              rel="noreferrer" >
                              <p
                                className="float-right left-5 bottom-0 w-fit cursor-pointer relative bg-light px-4 py-1 rounded shadow-xl transition-all  duration-300 ease-in-out hover:bg-blue-500 hover:text-light hover:fill-[#f6f6f6]"
                                onClick={() => setDescrShow(true)}
                              >
                                <span className="text-lg relative bottom-1">{project.button.value[lang]}</span>

                                <span className="float-right mr-2 relative top-1.5 ml-3"  data-aos="fade-right"
                          data-aos-delay={300}><ImageSvg type='east' width={25} /></span>
                              </p>
                            </HashLink>
                          ) : null}
                        </h1>

                        <p
                          data-aos="fade-up"
                          data-aos-delay={300}
                          className="text-md w-2/3 mt-40"
                        >
                          {project.par.value[lang]}
                        </p>
                        {project.par.list ? (
                          <ol  data-aos="fade-up"
                          data-aos-delay={500} className="list-decimal ml-5 font-semibold py-1  ">
                            {project.par.list.value[lang].map((list, index) => (
                              <li key={index} role="region" className="text-sm">
                                {list}
                              </li>
                            ))}
                          </ol>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </SplideSlide>
              ))}
              <div className="splide__arrows hidden" />
            </Splide>
            {/* End Project List */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsDesktop;
