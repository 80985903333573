import React from 'react'

const MenuSVG = ({ type, stroke, fill, width }) => {
    switch (type) {
        case 'hamburger':
            return (

                <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 8H13.75M5 12H19M10.25 16L19 16" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            )
        case 'close':
            return (
                <svg width={width} height={width} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke={stroke} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                </svg>
            )
        default:
            break;
    }
}

export default MenuSVG