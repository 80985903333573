import React from 'react';
import { HashLink } from 'react-router-hash-link';

const ImgComponent = ({ src, alt, title, href, animation, style }) => {
  return (
    <div 
      data-aos={animation ? animation.type : undefined}
      data-aos-delay={animation ? animation.delay: undefined}>
      {href ? (
        <HashLink to={href} target="_blank" rel="noopener noreferrer" aria-label='title'>
          <img
            src={src}
            alt={alt}
            title={title}
            className={style}
            sizes="(max-width: 710px) 120px,
            (max-width: 991px) 193px,
            278px"
          />
        </HashLink>
      ) : (
        <img
          src={src}
          alt={alt}
          title={title}
          className={style}
          sizes="(max-width: 710px) 120px,
          (max-width: 991px) 193px,
          278px"
        />
      )}
    </div>
  );
};

export default ImgComponent;