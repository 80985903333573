import React from "react";

const ImageSvg = ({ type, width, fill, stroke }) => {
  switch (type) {
    case "bender":
      return (
        <svg viewBox="0 0 192 192" width={width} fill="#009DE0">
          <g>
            <polygon points="37.9,57.8 37.9,37.9 59.2,37.9 59.2,32 32,32 32,57.8 37.9,57.8 	" />
            <polygon points="160,57.8 160,32 132.8,32 132.8,37.9 154.1,37.9 154.1,57.8 160,57.8 	" />
            <polygon points="154.1,134.2 154.1,154.1 132.8,154.1 132.8,160 160,160 160,134.2 154.1,134.2 	" />
            <polygon points="32,134.2 32,160 59.2,160 59.2,154.1 37.9,154.1 37.9,134.2 32,134.2 	" />
            <polyline points="108.1,65.3 127,98.3 132.1,95.4 113.2,62.4 	" />
            <path
              d="M110.2,87.7v-3h-5v3l1.7,2.4c5.5-3.8,9-10.1,9-17.2c0-11.6-9.4-21-21.1-21H67.5c-0.8,0-1.5,0.3-2.1,0.9
		c-0.6,0.5-0.9,1.3-0.9,2.1v82.3c0,0.8,0.3,1.5,0.9,2.1c0.6,0.5,1.3,0.9,2.1,0.9h42.7c15.4,0,27.8-12.4,27.8-27.7
		c0-15.3-12.4-27.6-27.8-27.6V87.7v3c6,0,11.5,2.4,15.5,6.4c4,3.9,6.4,9.4,6.4,15.4c0,6-2.4,11.5-6.4,15.4c-4,3.9-9.4,6.4-15.5,6.4
		H70.5V57.8l24.4,0c4.2,0,8,1.7,10.7,4.4c2.8,2.7,4.4,6.5,4.4,10.7c0,5.1-2.6,9.6-6.5,12.4c-1.1,0.7-1.5,2.1-1.1,3.3
		c0.4,1.2,1.5,2.1,2.8,2.1h5V87.7z"
            />
          </g>
        </svg>
      );
    case "identitaDigitale":
      return (
        <svg viewBox="0 0 192 192" width={width}>
          <g>
            <ellipse
              transform="matrix(8.578975e-02 -0.9963 0.9963 8.578975e-02 25.769 219.3107)"
              fill="#FFD300"
              cx="132.4"
              cy="95.6"
              rx="22.3"
              ry="22.3"
            />

            <ellipse
              transform="matrix(8.578975e-02 -0.9963 0.9963 8.578975e-02 -40.789 146.8274)"
              fill="#933DCB"
              cx="59.6"
              cy="95.6"
              rx="22.3"
              ry="22.3"
            />
            <path
              fill="#4DE2AF"
              d="M111.3,74.8c8.8-8.8,8.8-23,0-31.8l-15-15L64.5,59.8l15,15C88.3,83.6,102.6,83.6,111.3,74.8z"
            />
            <path
              fill="#FF0057"
              d="M80.5,116.7c8.9-8.9,23.2-8.9,32-0.2l15.3,15.3L95.7,164l-15.3-15.3C71.6,139.9,71.6,125.5,80.5,116.7z"
            />
          </g>
        </svg>
      );
    case "notarizeMe":
      return (
        <svg viewBox="0 0 192 192" fill="#0092FF" width={width}>
          <g>
            <path
              d="M125.7,148.3c-23.6,13.3-53.5,9.3-72.7-9.9c-6.6-6.6-11.4-14.3-14.4-23c-1-3-4.2-4.5-7.2-3.5
		c-3,1-4.5,4.2-3.5,7.2c3.6,10.3,9.3,19.5,17.1,27.3c22.8,22.8,58.2,27.6,86.2,11.8c2.6-1.6,3.6-5.1,2.1-7.8
		C131.9,147.7,128.4,146.7,125.7,148.3z"
            />
            <path
              d="M107.4,36c11.9,2.3,22.7,8.1,31.3,16.6c6.8,6.8,11.7,14.8,14.7,23.8c1,3,4.2,4.5,7.1,3.6s4.5-4.2,3.6-7.1
		c-3.5-10.7-9.4-20.2-17.4-28.2c-10.2-10.2-23-17-37.1-19.7c-3-0.6-6,1.4-6.6,4.5c-0.4,1.9,0.3,3.8,1.5,5.1
		C105.3,35.2,106.3,35.7,107.4,36z"
            />
            <path
              d="M50.5,74.1c-1,2.2-0.5,4.7,1.1,6.4c0.5,0.5,1,0.8,1.6,1.1c2.8,1.3,6.2,0.1,7.5-2.7c1.9-4.1,4.5-7.8,7.7-11
		c15.2-15.3,40.1-15.3,55.4,0c3.8,3.8,6.6,8.1,8.6,13c1.2,2.9,4.4,4.3,7.3,3.1c2.9-1.2,4.3-4.4,3.1-7.3c-2.5-6.3-6.3-11.9-11.1-16.7
		c-19.7-19.7-51.6-19.7-71.3,0C56.3,64.1,53,68.9,50.5,74.1z"
            />
            <path
              d="M131.7,131.3c5.4-5.4,9.4-11.8,11.9-19c1-3-0.5-6.1-3.5-7.2c-3-1-6.1,0.5-7.2,3.5c-1.9,5.6-5.1,10.5-9.3,14.7
		c-15.3,15.2-40.2,15.3-55.4,0c-4.3-4.3-7.5-9.4-9.4-15.1c-1-3-4.2-4.5-7.1-3.5c-3,1-4.5,4.2-3.5,7.1c2.5,7.4,6.6,13.9,12.1,19.5
		C80.1,151,112,151,131.7,131.3z"
            />
            <path
              d="M167.6,91.1c-3.8-3.8-10.1-3.9-14.1-0.2L122.3,90c-1.6,0-3,0.5-4.1,1.7l-16.5,16.5l0.2-27
		c0.2-0.2,0.5-0.4,0.7-0.6c3.9-3.9,4-10.4,0-14.3c-3.9-3.9-10.4-4-14.3,0c-1.5,1.5-2.5,3.5-2.8,5.4L67.5,89.5L40.8,88
		c-0.5-1.1-1.2-2.2-2.1-3.1c-0.7-0.7-1.4-1.2-2.2-1.7C38.9,71.7,44.6,61,53,52.6c8.9-8.9,20.2-14.7,32.5-16.9
		c3.1-0.5,5.1-3.5,4.6-6.5c-0.5-3-3.5-5.1-6.5-4.5c-14.7,2.5-28,9.4-38.5,20C34.1,55.6,27.1,69.5,24.8,84.5
		c-0.1,0.1-0.3,0.3-0.5,0.4c-3.9,3.9-4,10.4,0,14.3c3.9,3.9,10.4,4,14.3,0c0,0,0,0,0.1-0.1l30.6,1.7c1.6,0.1,3.1-0.5,4.3-1.7
		l16.8-16.8l-0.2,28.1c0,0,0,0,0,0c-3.9,3.9-4,10.4,0,14.3c3.9,3.9,10.4,4,14.3,0c1.8-1.8,2.8-4.3,2.9-6.7l16.9-16.9l26.7,0.8
		c0.5,1.2,1.2,2.4,2.2,3.4c0.6,0.6,1.3,1.2,2,1.6c-2,10.5-6.8,20.4-13.9,28.5c-2,2.2-1.8,5.6,0.2,7.7c0.1,0.1,0.2,0.2,0.3,0.3
		c2.4,2,6,1.8,8-0.5c9.2-10.4,15.1-23.3,17.1-36.9c0.2-0.2,0.5-0.4,0.7-0.6C171.6,101.5,171.6,95,167.6,91.1z M33.4,93.9
		c-1,1-2.7,1-3.7,0s-1-2.7,0-3.7s2.7-1,3.7,0S34.4,92.9,33.4,93.9z M93.5,71.6c1-1,2.7-1,3.7,0s1,2.7,0,3.7c-1,1-2.6,1-3.7,0
		C92.5,74.3,92.5,72.6,93.5,71.6z M99.3,119.5c-1,1-2.7,1-3.7,0s-1-2.7,0-3.7s2.7-1,3.7,0C100.3,116.8,100.3,118.5,99.3,119.5z
		 M162.3,100.1c-1,1-2.7,1-3.7,0s-1-2.6,0-3.7c1-1,2.7-1,3.7,0S163.3,99.1,162.3,100.1z"
            />
          </g>
        </svg>
      );
    case 'motoreInteresse':
      return (
        <svg width={width} viewBox="0 0 170 170">
          <g>
            <circle fill="none" stroke={stroke} strokeWidth="10px" strokeMiterlimit="10" cx="85" cy="85" r="80" />
            <g>
              <polygon fill={fill} points="99.46 58.78 99.46 114.78 85.22 114.78 85.22 84.62 71.62 107.1 70.18 107.1 56.66 84.7 56.66 114.78 42.34 114.78 42.34 58.78 56.66 58.78 70.9 82.7 85.22 58.78 99.46 58.78" />
              <rect fill={fill} x="108.34" y="58.78" width="14.32" height="56" />
            </g>
          </g>
        </svg>
      )
    case 'show':
      return (
        <svg viewBox="0 -960 960 960" fill={fill} width={width}>
          <path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" /></svg>
      )
    case 'hide':
      return (
        <svg viewBox="0 -960 960 960" width={width} fill={fill}><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z" /></svg>
      )
    case 'east':
      return (
        <svg viewBox="0 -960 960 960" width={width} fill={fill}><path d="m600-200-57-56 184-184H80v-80h647L544-704l56-56 280 280-280 280Z" /></svg>
      )
    case 'linkedin':
      return (
        <svg width={width} viewBox="0 0 16 16" fill="none">
          <path fill={fill} d="M12.225 12.225h-1.778V9.44c0-.664-.012-1.519-.925-1.519-.926 0-1.068.724-1.068 1.47v2.834H6.676V6.498h1.707v.783h.024c.348-.594.996-.95 1.684-.925 1.802 0 2.135 1.185 2.135 2.728l-.001 3.14zM4.67 5.715a1.037 1.037 0 01-1.032-1.031c0-.566.466-1.032 1.032-1.032.566 0 1.031.466 1.032 1.032 0 .566-.466 1.032-1.032 1.032zm.889 6.51h-1.78V6.498h1.78v5.727zM13.11 2H2.885A.88.88 0 002 2.866v10.268a.88.88 0 00.885.866h10.226a.882.882 0 00.889-.866V2.865a.88.88 0 00-.889-.864z" />
        </svg>
      )
    default:
      break;
  }
};

export default ImageSvg;
