import { OpenerConfig } from "../../config";
import OpenerViewDesktop from "./OpenerView_Desktop";
import OpenerViewMobile from "./OpenerView_Mobile";

const OpenerView = ({ lang, screen}) => {
  const { title, subTitle, subSubTitle, background } = OpenerConfig(screen);

  return (
    <section className="relative h-screen w-full">
      {screen > 1380 ?
        <OpenerViewDesktop
          lang={lang}
          title={title}
          subTitle={subTitle}
          subSubTitle={subSubTitle}
          background={background}
        />
      :
        <OpenerViewMobile
          lang={lang}
          title={title}
          subTitle={subTitle}
          subSubTitle={subSubTitle}
          background={background}
        />
      }
    </section>
  );
};

export default OpenerView;
