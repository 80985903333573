import React from 'react';
import TagComponent from '../atoms/Tag.Component';
import TitleComponent from '../atoms/Title.Component';
import ImgComponent from '../atoms/Img.Component';

const ContentBox = ({ tags, img, title, par }) => {
    return (
        <div>
            {tags
                ? (
                    <div className='overflow-hidden'>
                        {tags.map((tag, index) => (
                            <TagComponent key={index} value={tag} />
                        ))}
                    </div>
                )
                : null
            }
            {img
                ? <ImgComponent src={img.src} alt={img.alt} title={img.title} style={img.style} />
                : null
            }
            {Array.isArray(title) ? (
                <div>
                    {title.map((t, index) => (
                        <TitleComponent
                            key={index}
                            title={t}
                        />
                    ))}
                </div>
            ) : (
                <TitleComponent
                    title={title}
                />
            )}
            <p
                className={par.style}
                data-aos={par.animation ? par.animation.type : undefined}
                data-aos-delay={par.animation ? par.animation.delay : undefined}
            >
                {par.value}
            </p>
        </div>
    );
}

export default ContentBox;
