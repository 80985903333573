import React from 'react';

const PolylineSVG = ({ w, h, points, stroke, strokeWidth, referring }) => {
  return (
    <svg
      className={`fill-none h-full w-full relative`}
      stroke={stroke}
      strokeWidth={strokeWidth}
      viewBox={`0 0 ${w} ${h}`}
    >
      <g>
        <polyline stroke={stroke} points={points} ref={referring} />
      </g>
    </svg>
  )
}

export default PolylineSVG