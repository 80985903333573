import React from 'react'
import ContentBox from '../../components/molecules/ContentBox.Component'
import LogoImageSVG from '../../components/atoms/LogoImageSVG'
import MailForm from '../../components/molecules/MailForm.Component'
import { Link } from 'react-router-dom'
import ImageSvg from '../../components/atoms/ImageSVG'

const GetInTouchMobile = ({ lang, title, par, information, animation, form, errors }) => {
    return (
        <div className='w-full h-full bg-dark bg-opacity-70'>
            <div data-aos={animation} data-aos-delay={300} className='w-full'>
                <div className='p-10'>
                    <ContentBox

                        title={{ value: title.value[lang], style: 'text-light font-bold text-5xl' }}
                        par={{ value: par.value[lang], style: 'mt-5 text-md font-regular text-light' }}
                    />
                </div>
                <div className='p-5'>
                    <div className='rounded py-10 px-5 bg-[#eee] bg-opacity-10 w-full text-md'>
                        <MailForm lang={lang} form={form} errors={errors}/>
                    </div>
                </div>
                <div className='mt-10 text-md text-center text-light font-light'>
                    <p className='mt-2'>
                        <span className='font-light'>&copy;</span> {new Date().getFullYear()} <a href='https://www.opencluster.it/' aria-label="Open Cluster" className='underline font-bold hover:opacity-90'>OpenCluster Srl</a>
                    </p>
                    <p className='mt-2'> {information.address.value[lang]} </p>
                    <p className='mt-2'> {information.vat.value[lang]} </p>
                    <Link to="/privacy" target="_blank" rel="noopener noreferrer" aria-label="privacy-policy"><p className='underline text-blue-300 cursor-pointer hover:text-blue-400' >{lang === 'it' ? 'Termini e Condizioni' : 'Terms and Condition'}</p></Link>
                    <div className='mt-5 flex justify-center items-center'>
                        <Link to='https://it.linkedin.com/company/open-cluster-s-r-l' target="_blank" rel="noopener noreferrer"aria-label="linkedin"><ImageSvg type='linkedin' width={50} fill='#f6f6f6'/></Link>
                    </div>
                </div>
                <div className='flex justify-center items-center mt-10'>
                    <LogoImageSVG type='portrait' stroke='#f6f6f6' fill='#f6f6f6' width={200} />
                </div>
            </div>
        </div>
    )
}

export default GetInTouchMobile