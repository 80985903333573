import React from 'react';

const ButtonComponent = ({ onClick, label, animation, style }) => {
  return (
    <button
      onClick={onClick}
      className={style}
      data-aos={animation ? animation.type : undefined}
      data-aos-delay={animation ? animation.delay : undefined}
    >
      {label}
    </button>
  );
};

export default ButtonComponent;
