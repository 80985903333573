import React from 'react'
import ContentBox from '../../components/molecules/ContentBox.Component'
import MailForm from '../../components/molecules/MailForm.Component'
import LogoImageSVG from '../../components/atoms/LogoImageSVG'
import { Link } from 'react-router-dom'
import ImageSvg from '../../components/atoms/ImageSVG'

const GetInTouchDesktop = ({ lang, title, par, information, animation, form, errors }) => {
    return (
        <div className='grid grid-cols-1 lg:grid-cols-6 w-full h-[1300px] pb-53 overflow-hidden'>
            <div></div>
            <div className='lg:col-span-4 relative z-10'>
                <div className='p-10 w-2/3 h-full mt-64 relative' data-aos={animation} data-aos-delay={300}>
                    <div className='w-[500px]'>
                        <ContentBox
                            title={{ value: title.value[lang], style: 'text-light font-bold lg:text-8xl text-2xl' }}
                            par={{ value: par.value[lang], style: 'mt-5 text-2xl font-thin text-light' }}
                        />

                    </div>
                    <div className='mt-20 text-xl text-light font-light'>
                        <p className='mt-2'>
                            <span className='font-light'>&copy;</span> {new Date().getFullYear()} <a href='https://www.opencluster.it/' aria-label="Open Cluster" className='underline font-bold hover:opacity-90'>OpenCluster Srl</a>
                        </p>
                        <p className='mt-2'> {information.address.value[lang]} </p>
                        <p className='mt-2'> {information.vat.value[lang]} </p>
                        <Link to="/privacy" target="_blank" rel="noopener noreferrer" aria-label='privacy-policy' className='w-fit'><p className='underline text-blue-300 cursor-pointer hover:text-blue-400'>{lang === 'it' ? 'Termini e Condizioni' : 'Terms and Condition'}</p></Link>
                        <div className='mt-5'>
                            <Link to='https://it.linkedin.com/company/open-cluster-s-r-l'target="_blank" rel="noopener noreferrer" aria-label='linkedin'><ImageSvg type='linkedin' width={40} fill='#f6f6f6' /></Link>
                        </div>
                    </div>
                    <div className='absolute w-1/2 top-32 left-full' data-aos='fade-left' data-aos-delay={1200}>
                        <p className='text-light text-2xl font-bold mb-5'>{form.message.value[lang]}</p>
                        <MailForm lang={lang} form={form} errors={errors} />
                    </div>
                </div>
            </div>
            <div></div>
            <div className='absolute bottom-10 left-1/2'>
                <LogoImageSVG type='portrait' stroke='#f6f6f6' fill='#f6f6f6' width={200} />
            </div>
        </div>
    )
}

export default GetInTouchDesktop