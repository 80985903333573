import React, { useEffect, useState } from "react";
import OpenerView from "./views/opener/OpenerView";
import AboutView from "./views/about/AboutView";
import Intrasection from "./views/intrasection/Intrasection";
import AnimatedLine from "./components/LineAnimation/AnimatedLine";
import Projects from "./views/projects/Projects";
import GetInTouch from "./views/getInTouch/GetInTouch";
import NavigationBar from "./components/molecules/NavigationBar";
import { GetInTouchConfig } from "./config";

const OnePage = () => {
  const [lang, setLang] = useState("it");
  const [scrollColor, setScrollColor] = useState("#f6f6f6"); // Default color
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [generalWidth, setgeneralWidth] = useState(1600);

  useEffect(() => {
    const getScreenSize = () => {
      const currScreenWidth = window.innerWidth;
      setScreenWidth(currScreenWidth);
      if (currScreenWidth >= 1980) {
        setgeneralWidth(1600);
      } else if (currScreenWidth <= 1536) {
        setgeneralWidth(1000);
      } else if (currScreenWidth < 1980) {
        setgeneralWidth(1300);
      }
    };
    const handleResize = () => {
      getScreenSize();
    };

    getScreenSize();
    window.addEventListener("resize", handleResize);

    const handleScroll = () => {
      const scrollPosition = window.scrollY - 1200;
      const contactOffsetTop = document.getElementById("contact").offsetTop;
      const projectOffsetTop = document.getElementById("project").offsetTop;
      const intrasectionOffsetTop =
        document.getElementById("intrasection").offsetTop;
      const aboutOffsetTop = document.getElementById("about").offsetTop;
      const openerOffsetTop = document.getElementById("opener").offsetTop;

      if (scrollPosition >= contactOffsetTop) {
        setScrollColor("#f6f6f6");
      } else if (scrollPosition >= projectOffsetTop) {
        setScrollColor("#212121");
      } else if (scrollPosition >= intrasectionOffsetTop) {
        setScrollColor("#f6f6f6");
      } else if (scrollPosition >= aboutOffsetTop) {
        setScrollColor("#212121");
      } else if (scrollPosition >= openerOffsetTop) {
        setScrollColor("#f6f6f6");
      } else {
        setScrollColor("#f6f6f6");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChangeLang = (selectedLang) => {
    setLang(selectedLang);
  };

  return (
    <>
      <header id="main_opener_content" className="abolute bottom-0 left-0 right-0 bg-opener w-full h-full bg-cover bg-center bg-no-repeat xl-3:bg-fixed bg-scroll overflow-hidden">
        <OpenerView lang={lang} screen={screenWidth} />
      </header>
      <div className="absolute z-20">
        <NavigationBar
          screen={screenWidth}
          color={scrollColor}
          lang={lang}
          changeLang={handleChangeLang}
        />
      </div>
      <div className="relative">
        <div id="main-content" className="absolute top-0">
          {/* SECTION ABOUT */}
          <section id="about">
            <AboutView lang={lang} screen={screenWidth} />
          </section>
          {/* SECTION INTERSECTION */}
          <section id="intrasection">
            <Intrasection lang={lang} screen={screenWidth} />
          </section>
          {/* SECTION PROJECT */}
          <section id="project" className="bg-light">
            <Projects lang={lang} screen={screenWidth} />
          </section>
          {/* SECTION GET IN TOUCH */}
          <section
            id="contact"
            className="abolute bottom-0 left-0 right-0 w-full h-full bg-cover bg-center bg-no-repeat xl-3:bg-fixed bg-scroll overflow-hidden bg-dark bg-getintouch bg-opacity-"
          >
            <GetInTouch
              lang={lang}
              screen={screenWidth}
            />
          </section>
        </div>
      </div>
      {screenWidth > 1380 ? (
        <div className="z-5">
          <AnimatedLine w={generalWidth} />
        </div>
      ) : null}
    </>
  );
};

export default OnePage;
