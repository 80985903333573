import React from "react";
import TitleComponent from "../../components/atoms/Title.Component";
import { HashLink } from "react-router-hash-link";

const OpenerViewMobile = ({
  lang,
  title,
  subTitle,
  subSubTitle,
}) => {
  return (
    <div
    id="opener"
    className="w-full h-full overflow-hidden"
  >
    <div className="w-full h-full flex items-center justify-center relative text-center">
        <div>
    <TitleComponent
          title={{
            value: title.value[lang],
            style: "md:text-8xl text-lg text-light font-thin",
          }}
          subTitle={{ value: subTitle.value[lang], style: "font-bold md:text-8xl text-[50px] relative top-2" }}
          subSubTitle={{
            value: subSubTitle.value[lang],
            style: "text-sm px-5 py-2 w-full text-center md:block bg-opacity-20 rounded-full bg-light md:text-3xl uppercase relative left-1 top-8 md:font-semibold font-light",
          }}
          level={1}
        />
         <div className="mt-20 w-full h-full" data-aos='fade-up' data-aos-delay={300}>
            <HashLink  to='#contact' aria-label="contacts" className="ml-2 transition-colors duration-300 ease-in-out bg-transparent w-full h-fit border-2 rounded hover:bg-light hover:text-dark-21 border-[#f6f6f6] mt-12 text-sm md:text-xl text-light font-bold px-10 md:px-20 py-5">{lang === 'it' ? 'Contattaci': 'Contact Us'}</HashLink>
            <HashLink  to='#project' aria-label="projects" className="ml-2 transition-colors duration-300 ease-in-out bg-light w-full h-fit border-2 rounded hover:opacity-90 border-[#f6f6f6] mt-12 text-sm md:text-xl text-dark-21 font-bold px-10 md:px-20 py-5">{lang === 'it' ? 'Vedi Progetti': 'See Projects'}</HashLink>
        </div>
        </div>
       
      </div>
  </div>
  );
};

export default OpenerViewMobile;
