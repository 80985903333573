import React from "react";
import { AboutConfig } from "../../config";
import AboutViewDesktop from "./AboutView_Desktop";
import AboutViewMobile from "./AboutView_Mobile";

const AboutView = ({ lang, screen }) => {
  const { title, par, tags, animation, skills } = AboutConfig(screen);

  return screen > 1380 ? (
    <AboutViewDesktop
      lang={lang}
      title={title}
      par={par}
      tags={tags}
      animation={animation}
      skills={skills}
    />
  ) : (
    <AboutViewMobile
      lang={lang}
      title={title}
      par={par}
      tags={tags}
      animation={animation}
      skills={skills}
    />
  );
};

export default AboutView;
